import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Header from "../../components/header";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import Context from "../../context/context";
import axios from "axios";
import Loader from "../../components/Loader";
import { debounce } from "underscore";
import toast from "react-hot-toast";
import convertTimeToCountry from "../../utility/convertTimeToCountry";
import ErrosToast from "../../components/ErrosToast";
import "./ShipmentLoading.css";

const ShipmentLoading = () => {
  // const {selectedItems, setSeletedItems} = useContext(Context);

  const [selectAll, setSelectAll] = useState(false);
  const [shipmentData, setShipmentData] = useState([]);
  const [sentShipmentData, setSentShipmentData] = useState([]); // sent data in this
  const [unsentShipmentData, setUnsentShipmentData] = useState([]); // unsent data stored in this
  const [selectedShipmentItems, setSelectedShipmentItems] = useState([]);
  const [processShipItems, setProcessShipItems] = useState([]); // to store data in sent or send data to shipment Loading
  const [sentCheckBox, setSentCheckBox] = useState(false);
  const [unsentCheckBox, setUnsentCheckBox] = useState(true);
  const [shipmentNum, setShipmentNum] = useState("");

  const [poSearch, SetPoSearch] = useState("");
  const [stySearch, SetStySearch] = useState("");
  const [BoxGroupSearch, SetBoxGroupSearch] = useState("");
  const [fromSearch, SetFromSearch] = useState("");
  const [toSearch, SetToSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false); // for loading data in shipment loading
  const [all_tot_qty, setAll_tot_qty] = useState(0); // for
  const [all_tot_carton, setAll_tot_carton] = useState(0);

  const [selectedTot_qty, setSelectedTot_qty] = useState(0); //selecteded total quantity for print in confirmation box

  const [isProcessLoading, setIsProcessLoading] = useState(false); //loading for process button

  const [isRestoreLoading, setIsRestoreLoading] = useState(false); //loading for process button

  const [showModal, setShowModal] = useState(false);
  const [inputForBarcode, setInputForBarcode] = useState("");
  const [boxnumberofBarcode, setboxnumberofBarcode] = useState("");
  const [totalQuantityofBarcode, settotalQuantityofBarcode] = useState("");

  const [shipmentSuggestion, setShipmentSuggestion] = useState([]);
  const [storedShipmentSuggestions, setStoredShimentSuggestions] = useState([]); // store shipment suggestion.
  const [showSuggestions, setShowSuggestions] = useState(false);

  const navigate = useNavigate();

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  // console.log("sent shipment data", sentShipmentData);
  // console.log("unsent shipment data", unsentShipmentData);

  const fetchData = async (pageNo, limit) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/barcode/getShipLoadingData?sent=${sentCheckBox}&unsent=${unsentCheckBox}&PO=${poSearch}&STY=${stySearch}&From=${fromSearch}&To=${toSearch}&BoxGroup=${BoxGroupSearch}`
      );
      // console.log("resta", res);
      const tot_qty = res.data.barcodes.reduce(
        (acc, b) => acc + parseInt(b.TOT_QTY, 10),
        0
      );

      const tot_carton_no = res.data.barcodes.length;

      // console.log("tottttt", tot_carton_no.length);
      // console.log('tot', tot_carton_no)
      setAll_tot_qty(tot_qty);
      setAll_tot_carton(tot_carton_no);
      setSelectAll(false);
      setSelectedShipmentItems([]);
      setSelectedTot_qty(0);

      setShipmentData(res.data.barcodes);
      // setPage(pageNo);
      // setInitialFetchDone(true);
      // if (res.data.barcodes.length < limit) {
      //   setHasMore(false);
      // }
      // setIsLoading(false);
    } catch (err) {
      // console.log("error: ", err);
      // setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetchData(0, 30)
    // console.log("useEffect called first");
    // if (!initialFetchDone) {
    fetchData();
    // Set initialFetchDone to true after initial fetch
    // return;
    // }

    // const tableContainer = document.querySelector(".table-container");
    // if (!tableContainer) return;

    // const handleScroll = () => {
    //   const table = tableContainer.querySelector(".table");

    //   if (!table) return;

    //   const tableBody = table.querySelector("tbody");

    //   // Add this check
    //   if (!tableBody) return;

    //   const lastRow = tableBody.querySelector("tr:last-child");

    //   if (lastRow) {
    //     console.log("boxapi length", shipmentData.length);
    //     const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
    //     const containerOffset =
    //       tableContainer.scrollTop + tableContainer.clientHeight;
    //     const bottomOffset = 20;

    //     if (
    //       containerOffset > lastRowOffset - bottomOffset &&
    //       hasMore &&
    //       !isLoading
    //     ) {
    //       fetchData(0, 30 * ((shipmentData.length + 30) / 30));
    //     }
    //   }
    // };

    // tableContainer.addEventListener("scroll", handleScroll);

    // return () => {
    //   tableContainer.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  useEffect(() => {
    setSelectAll(false);
    setSelectedShipmentItems([]);
    setSelectedTot_qty(0);
  }, [sentCheckBox, unsentCheckBox]);

  // console.log('total quantity', selectedTot_qty)

  useEffect(() => {
    // for loadng onlysent data to set total quantity
    // setIsLoading(true);
    axios
      .get(`/barcode/getShipLoadingData?sent=true&unsent=false`)
      .then((res) => {
        // console.log("res unsebt", res);
        setSentShipmentData(res.data.barcodes);
        // console.log("line168", sentShipmentData);
      });
  }, []);
  // console.log('sent data', sentData)

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedShipmentItems([...selectedShipmentItems, item]);
      setSelectedTot_qty((prev) => prev + parseInt(item.TOT_QTY, 10));
    } else {
      setSelectedShipmentItems(
        selectedShipmentItems.filter((selectedItem) => selectedItem !== item)
      );
      setSelectedTot_qty((prev) => prev - parseInt(item.TOT_QTY, 10));
    }
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // console.log("New select all:", newSelectAll);

    if (newSelectAll) {
      // console.log('sentItems', sentItems)
      const sentableItems = shipmentData.filter((item) => item.csv !== "1");
      // console.log("Sentable items:", sentableItems);
      // console.log("sent checkbox", sentCheckBox);
      const itemsToSelect = sentCheckBox ? sentableItems : shipmentData;
      // console.log("Items to select:", itemsToSelect);

      setSelectedShipmentItems(itemsToSelect);
      // console.log("Selected items:", selectedItems);

      const totalQty = itemsToSelect.reduce(
        (acc, b) => acc + parseInt(b.TOT_QTY, 10),
        0
      );
      // console.log("Total quantity:", totalQty);

      setSelectedTot_qty(totalQty);
    } else {
      setSelectedShipmentItems([]);
      setSelectedTot_qty(0);
    }
  };

  const handleSendToTransferData = async () => {
    try {
      if (!selectedShipmentItems || selectedShipmentItems.length === 0) {
        alert("Please select something to process");
        return;
      }

      if (
        shipmentNum === "" ||
        shipmentNum === undefined
        // ||
        // !shipmentSuggestion.includes(shipmentNum)
      ) {
        alert("Please select factoryShipment from Suggetion ");

        // console.log("shipi", shipmentNum);
        return;
      }
      const isSuggestionShipmentNo =
        storedShipmentSuggestions.includes(shipmentNum);
      if (!isSuggestionShipmentNo) {
        toast.remove();
        return toast.error("Please select factoryShipment from Suggetion");
      }
      setIsProcessLoading(true);
      if (
        window.confirm(
          `Are you sure to send  below data from shipmentLoading to TransferData\n Total boxes :  ${selectedShipmentItems.length} boxes \n Total Quantity : ${selectedTot_qty}`
        )
      ) {
        await sentToLoadingAPI();
        setShipmentNum("");
        setIsProcessLoading(false);
        // navigate("/csv");
      } else {
        setIsProcessLoading(false);
        setSelectedShipmentItems([]);
        setSelectAll(false);
        setSelectedTot_qty(0);
      }
    } catch (error) {
      setIsProcessLoading(false);
      console.error("Error processing APIs:", error);
    }
  };
  // console.log('process loading', isProcessLoading)

  const restoreToLoadingAPI = () => {
    return new Promise((resolve, reject) => {
      const postData = {
        barcodes: selectedShipmentItems.map((item) => item.id),
      };
      axios
        .post("/barcode/unloadShipment", postData)
        .then((res) => {
          console.log("unload data", res.data);
          //setProcessItems(res.data);
          toast.remove();
          toast.success("Restored done");

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
      // resolve();
    });
  };

  const handleRestoreToExFactory = async () => {
    try {
      if (!selectedShipmentItems || selectedShipmentItems.length === 0) {
        alert("Please select something to restore");
        return;
      }
      setIsRestoreLoading(true);
      const confirmRestore = window.confirm(
        `Are you sure to restore below data from ShipLoading to exFactory\n Total Boxes :  ${selectedShipmentItems.length} Boxes \n Total Qunatity : ${selectedTot_qty}`
      );
      if (confirmRestore) {
        await restoreToLoadingAPI();

        setIsRestoreLoading(false);
        setIsLoading(true);
        const res = await axios.get(
          `/barcode/getShipLoadingData?sent=${sentCheckBox}&unsent=${unsentCheckBox}&PO=${poSearch}&STY=${stySearch}&BoxGroup=${BoxGroupSearch}`
        );
        // console.log("resta", res);
        const tot_qty = res.data.barcodes.reduce(
          (acc, b) => acc + parseInt(b.TOT_QTY, 10),
          0
        );

        const tot_carton_no = res.data.barcodes.length;

        // console.log("tottttt", tot_carton_no.length);
        // console.log('tot', tot_carton_no)
        setAll_tot_qty(tot_qty);
        setAll_tot_carton(tot_carton_no);
        // if (res.data.barcodes.length < 30) {
        //   setHasMore(false);
        // } else {
        //   setHasMore(true);
        // }
        setShipmentData(res.data.barcodes);

        setSelectedShipmentItems([]);
        setSelectAll(false);
        setSelectedTot_qty(0);
        setIsLoading(false);
        // navigate("/exFactory");
      } else {
        setIsRestoreLoading(false);
        setSelectedShipmentItems([]);
        setSelectAll(false);
        setSelectedTot_qty(0);
      }
    } catch (error) {
      setIsRestoreLoading(false);
      console.error("Error processing APIs:", error);
    }
  };
  const sentToLoadingAPI = () => {
    return new Promise((resolve, reject) => {
      const postData = {
        barcodes: selectedShipmentItems.map((item) => item.id),
        ShipNo: shipmentNum,
      };
      // console.log("post daa", postData);
      axios
        .post("/barcode/loadShipment", postData)
        .then((res) => {
          // console.log("res data", res.data);
          setProcessShipItems(res.data);
          navigate("/csv");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
      resolve();
    });
  };

  const handleShipmentNum = async (e) => {
    // const name = e.target.name;
    const newSearchItem = e.target.value;
    // console.log('name',name)
    // console.log("value", newSearchItem);
    setShipmentNum(newSearchItem);

    if (newSearchItem) {
      setShowSuggestions(true);
      try {
        const response = await axios.get(
          `/shipInfo/getUnsent?shipNo=${newSearchItem}`
        );
        // console.log("shipment loading data", response.data.shipData);
        const sentSuggestionData = response.data.shipData
          .filter((suggestion) => suggestion.sent === 0)
          .map((suggestion) => suggestion.shipNo);
        // console.log("after filtered shupment loading data", sentSuggestionData);
        setShipmentSuggestion(sentSuggestionData);
        setStoredShimentSuggestions(sentSuggestionData);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setShowSuggestions(false);
      setShipmentSuggestion([]);
    }
  };

  // console.log("shipment stored suggestions", storedShipmentSuggestions);

  const handleSuggestionClick = (suggestion) => {
    setShipmentNum(suggestion);
    setShipmentSuggestion([]);
  };
  // console.log('shipment num',shipmentNum)

  // const location = useLocation();
  // const selectedItems = location.state?.selectedItems || [];
  // console.log('selected items', selectedItems)
  // console.log("selected items", selectedShipmentItems);

  const filterHandle = async (
    e,
    poSearch,
    stySearch,
    fromSearch,
    toSearch,
    BoxGroupSearch,
    sentCheckBox,
    unsentCheckBox
  ) => {
    e.preventDefault();

    // console.log("whats the name", e.target.name);

    let sentValue, unsentValue;

    if (e.target.name === "sentCheckBox") {
      sentValue = e.target.checked;
      unsentValue = false;
    } else if (e.target.name === "unsentCheckBox") {
      unsentValue = e.target.checked;
      sentValue = false;
    } else {
      sentValue = sentCheckBox;
      unsentValue = unsentCheckBox;
    }
    // console.log("inside sent checkbox", sentCheckBox);
    // console.log("inside unsent checkbox", unsentCheckBox);
    setIsLoading(true);
    setSelectAll(false);
    const encodedSentValue = encodeURIComponent(sentValue);
    const encodedUnsentValue = encodeURIComponent(unsentValue);
    const encodedPoSearch = encodeURIComponent(poSearch);
    const encodedStySearch = encodeURIComponent(stySearch);
    const encodedFromSearch = encodeURIComponent(fromSearch);
    const encodedToSearch = encodeURIComponent(toSearch);
    const encodedBoxGroupSearch = encodeURIComponent(BoxGroupSearch);
    await axios

      .get(
        `/barcode/getShipLoadingData?PO=${encodedPoSearch}&STY=${encodedStySearch}&From=${encodedFromSearch}&To=${encodedToSearch}&BoxGroup=${encodedBoxGroupSearch}&sent=${encodedSentValue}&unsent=${encodedUnsentValue}`
      )
      .then((res) => {
        setIsLoading(false);
        const tot_qty = res.data.barcodes.reduce(
          (acc, b) => acc + parseInt(b.TOT_QTY, 10),
          0
        );

        const tot_carton_no = res.data.barcodes.length;

        // console.log("tottttt", tot_carton_no.length);
        // console.log('tot', tot_carton_no)
        setAll_tot_qty(tot_qty);
        setAll_tot_carton(tot_carton_no);
        if (sentCheckBox === true && unsentCheckBox === false) {
          setSentShipmentData(res.data.barcodes);
        }
        setShipmentData(res.data.barcodes);
        if (res.data.barcodes.length < 30) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        // console.log("ress", res);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (
        e,
        poSearch,
        stySearch,
        fromSearch,
        toSearch,
        BoxGroupSearch,
        sentCheckBox,
        unsentCheckBox
      ) =>
        filterHandle(
          e,
          poSearch,
          stySearch,
          fromSearch,
          toSearch,
          BoxGroupSearch,
          sentCheckBox,
          unsentCheckBox
        ),
      1100,
      false
    ),
    []
  );

  const handleShowModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const verifyBarcode = (e) => {
    e.preventDefault();
    const el = shipmentData.find(
      (row) => row.barcodeParity === inputForBarcode
    );
    if (el !== undefined) {
      // Barcode matched
      if (!selectedShipmentItems.includes(el)) {
        // If the el is not already selected, add it to selectedItems
        setSelectedShipmentItems([...selectedShipmentItems, el]);
        setSelectedTot_qty((prev) => prev + parseInt(el.TOT_QTY, 10));
        // console.log("selectedItems", selectedShipmentItems, el);
        settotalQuantityofBarcode(el.TOT_QTY);
        setboxnumberofBarcode(el.SNo);
        setInputForBarcode("");
      }
    } else {
      toast.remove();
      ErrosToast("Barcode didn't match");
      return;
    }
  };

  // console.log("selected boxes ", selectedShipmentItems);
  // console.log("unsent boxes", unsentShipmentData);

  // const [shipmentNum, setShipmentNum] = useState("");
  // const [shipmentSuggestion, setShipmentSuggestion] = useState([
  //   "Shipment 1",
  //   "Shipment 2",
  //   "Shipment 3",
  // ]);
  // Example suggestions
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const suggestionsRef = useRef(null);
  // const handleShipmentNum = (e) => {
  //   setShipmentNum(e.target.value);

  // };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (activeSuggestion + 1 < shipmentSuggestion.length) {
        setActiveSuggestion((prev) => prev + 1);
        scrollIntoViewIfNeeded(activeSuggestion + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestion - 1 >= 0) {
        setActiveSuggestion((prev) => prev - 1);
        scrollIntoViewIfNeeded(activeSuggestion - 1);
      }
    } else if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleSuggestionClick(shipmentSuggestion[activeSuggestion]);
    }
  };

  const scrollIntoViewIfNeeded = (index) => {
    const element = suggestionsRef.current?.children[index];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  // const handleSuggestionClick = (suggestion) => {
  //   setShipmentNum(suggestion);

  // };
  return (
    <>
      <div className="fixed-top">
        <div>
          <Header />
        </div>
        {/* className="d-flex flex-wrap w-100" */}
        <div
          style={{
            background: "#f5f8fa",
            // border: "2px solid red",
            width: "100%",
            // marginLeft: "2%",
            background: "#ffff",
            paddingBottom: ".5%",
          }}
        >
          <div className="d-flex flex-wrap p-2">
            <div className="mx-4 mt-2">
              <form
                // onSubmit={(e) => {
                //   filterHandle(e, poSearch, stySearch, BoxGroupSearch);
                // }}
                className="d-flex flex-wrap"
                style={{ textTransform: "capitalize" }}
              >
                <div className="me-2">
                  <label className="fs-6">Style</label>
                  <input
                    className="form-control my-1"
                    type="text"
                    name="sty"
                    style={{ width: "130px", border: "2px solid black" }}
                    maxLength={8}
                    value={stySearch}
                    onChange={(e) => {
                      SetStySearch(
                        e.target.value.toUpperCase().replace(/%/g, "")
                      );
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        e.target.value.toUpperCase(),
                        fromSearch,
                        toSearch,
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    }}
                    placeholder="Style"
                    autoComplete="off"
                  />
                </div>
                <div className="me-2">
                  <label className="fs-6">PO#</label>
                  <input
                    className="form-control my-1"
                    type="text"
                    name="po"
                    style={{ width: "130px", border: "2px solid black" }}
                    maxLength={6}
                    value={poSearch}
                    onChange={(e) => {
                      SetPoSearch(
                        e.target.value.replace(/\D/g, "").toUpperCase()
                      );
                      OnChangeHandleFilter(
                        e,
                        e.target.value.replace(/\D/g, "").toUpperCase(),
                        stySearch,
                        fromSearch,
                        toSearch,
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    }}
                    placeholder="PO"
                    autoComplete="off"
                  />
                </div>
                <div className="me-2">
                  <label className="fs-6">box# from</label>
                  <input
                    className="form-control my-1"
                    type="text"
                    name="From"
                    maxLength={10}
                    style={{ width: "130px", border: "2px solid black" }}
                    value={fromSearch}
                    onChange={(e) => {
                      SetFromSearch(
                        e.target.value.toUpperCase().replace(/%/g, "")
                      );
                      // console.log("fromsearch", fromSearch);
                      if (poSearch === "") {
                        toast.remove();
                        ErrosToast("Please Enter PO  field First");
                        return;
                      }
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        e.target.value.toUpperCase(),
                        toSearch,
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    }}
                    placeholder="From"
                    autoComplete="off"
                  />
                </div>
                <div className="me-2">
                  <label className="fs-6">box# to</label>
                  <input
                    className="form-control my-1"
                    type="text"
                    name="To"
                    maxLength={10}
                    style={{ width: "130px", border: "2px solid black" }}
                    value={toSearch}
                    onChange={(e) => {
                      SetToSearch(
                        e.target.value.toUpperCase().replace(/%/g, "")
                      );
                      if (poSearch === "") {
                        toast.remove();
                        ErrosToast("Please Enter PO  field First");
                        return;
                      }
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        fromSearch,
                        e.target.value.toUpperCase(),
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    }}
                    placeholder="To"
                    autoComplete="off"
                  />
                </div>

                <div className="me-2">
                  <label className="fs-6">Box Group</label>
                  <input
                    className="form-control my-1"
                    type="text"
                    name="BoxGroup"
                    maxLength={15}
                    style={{ width: "130px", border: "2px solid black" }}
                    value={BoxGroupSearch}
                    onChange={(e) => {
                      SetBoxGroupSearch(e.target.value.toUpperCase());
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        fromSearch,
                        toSearch,
                        e.target.value.toUpperCase(),
                        sentCheckBox,
                        unsentCheckBox
                      );
                    }}
                    placeholder="BoxGroup"
                    autoComplete="off"
                  />
                </div>

                <div className="mt-4 pt-1 ms-1">
                  <button
                    className="btn btn-primary text-center"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      // filterHandle(e, poSearch, stySearch, BoxGroupSearch);
                    }}
                  >
                    Filter
                  </button>
                </div>
              </form>
            </div>
            <div className="ms-2 mt-2 ">
              <form className="d-flex">
                {unsentCheckBox && !showModal && (
                  <div>
                    <div className="mt-1">
                      <label className="fs-6">Factory Shipment</label>
                      <input
                        type="text"
                        name="shipmentNum"
                        minLength={6}
                        maxLength={20}
                        placeholder="6-20 byte"
                        style={{ width: "200px", border: "2px solid black" }}
                        value={shipmentNum}
                        className="form-control"
                        onChange={handleShipmentNum}
                        onKeyDown={handleKeyDown}
                        autoComplete="off"
                      />
                    </div>

                    {/* Dropdown suggestion list */}
                    {showSuggestions && (
                      <div
                        className="dropdown"
                        style={{
                          position: "absolute",
                          zIndex: 3,
                          width: "200px",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                        }}
                      >
                        {shipmentSuggestion.map((suggestion, index) => (
                          <div
                            className={`dropdown-row ${
                              index === activeSuggestion ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              backgroundColor:
                                index === activeSuggestion ? "#007bff" : "",
                              color:
                                index === activeSuggestion ? "white" : "black",
                            }}
                          >
                            {suggestion}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <div className="mt-4 ms-2 pt-1">
                  {unsentCheckBox && (
                    <button
                      className="btn btn-success fw-bold"
                      type="button"
                      onClick={handleSendToTransferData}
                      disabled={sentCheckBox}
                    >
                      {isProcessLoading ? <Spinner size="sm" /> : "Process"}
                    </button>
                  )}
                </div>
                <div className="mt-4 ms-1 pt-1">
                  {sentCheckBox && (
                    <button
                      className="btn btn-danger fw-bold"
                      type="button"
                      onClick={handleRestoreToExFactory}
                      disabled={unsentCheckBox}
                    >
                      {isRestoreLoading ? <Spinner size="sm" /> : "Restore"}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="mx-5" style={{ marginTop: "10px" }}>
              <form>
                {/* <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  value={unsentCheckBox}
                  checked={unsentCheckBox}
                  onChange={() => setUnsentCheckBox((prev) => !prev)}
                  label="UnSentTransfer"
                />
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  value={sentCheckBox}
                  checked={sentCheckBox}
                  onChange={() => setSentCheckBox((prev) => !prev)}
                  label="SentTransfer"
                />
              </Col>
            </Row> */}
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="unsentCheckBox"
                    id="exampleRadios1"
                    value={unsentCheckBox}
                    onChange={(e) => {
                      setUnsentCheckBox(true);
                      setSentCheckBox(false);
                      setSelectedTot_qty(0);
                      // setInitialFetchDone(false);
                      // OnChangeHandleFilter(
                      //   e,
                      //   poSearch,
                      //   stySearch,
                      //   fromSearch,
                      //   e.target.value.toUpperCase(),
                      //   BoxGroupSearch,
                      //   sentCheckBox,
                      //   unsentCheckBox
                      // );
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        BoxGroupSearch,
                        fromSearch,
                        toSearch,
                        sentCheckBox,
                        // unsentCheckBox,
                        e.target.checked
                      );
                    }}
                    checked={unsentCheckBox}
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    UnSent Transfer
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="sentCheckBox"
                    id="exampleRadios2"
                    value={sentCheckBox}
                    checked={sentCheckBox}
                    onChange={(e) => {
                      setSentCheckBox(true);
                      setUnsentCheckBox(false);
                      // setInitialFetchDone(false);
                      setSelectedTot_qty(0);
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        BoxGroupSearch,
                        fromSearch,
                        toSearch,
                        // sentCheckBox,
                        e.target.checked,
                        unsentCheckBox
                      );
                    }}
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Sent Transfer
                  </label>
                </div>
              </form>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div className="mx-3">Boxes : {all_tot_carton}</div>
              <div className="mx-3">Total_qty: {all_tot_qty}</div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div className="ms-3">
                Selected Boxes: {selectedShipmentItems.length}
              </div>
              <div className="ms-3">Selected Total_qty: {selectedTot_qty}</div>
            </div>
            <div>
              <button
                className="btn btn-primary mx-4 "
                style={{ marginTop: "13px" }}
                onClick={(e) => handleShowModal(e)}
              >
                Scan
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="mx-4 table-container"
        style={{ marginTop: "250px", marginBottom: "40px" }}
      >
        {/* {isLoading && !initialFetchDone ? ( */}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive mx-4 tableFixHead table-container">
            <table className="table table-bordered table-hover table-sm mt-4 table-header-for-all">
              <thead>
                <tr className="table-warning">
                  <th>
                    <span>
                      {/* <input type="checkbox" name="All" value="All" /> */}
                      <input
                        type="checkbox"
                        name="All"
                        value="All"
                        checked={
                          selectAll &&
                          (selectedShipmentItems.length ===
                            shipmentData.length ||
                            selectedShipmentItems.length ===
                              sentShipmentData.length)
                        }
                        onChange={handleSelectAllChange}
                      />
                    </span>
                    Select
                  </th>
                  {/* <th>S.No.</th> */}
                  <th>PO#</th>
                  <th>Style</th>
                  <th>Box#</th>

                  <th>NVE Barcode</th>
                  <th>Box Group</th>
                  <th>Factory</th>
                  <th>TOT_QTY</th>

                  <th>Created At</th>

                  <th>User</th>

                  {sentCheckBox && <th>ShipTime</th>}
                </tr>
              </thead>
              {shipmentData.length > 0 ? (
                <tbody>
                  {shipmentData &&
                    shipmentData.map((el, i) => {
                      // console.log("line no 1022=======>>>>>>", el);
                      return (
                        <>
                          <tr key={i}>
                            <td>
                              {i + 1}
                              <input
                                type="checkbox"
                                name="All"
                                value="All"
                                // checked={selectAll || selectedShipmentItems.includes(el)}
                                checked={selectedShipmentItems.includes(el)}
                                onChange={(e) => handleCheckboxChange(e, el)}
                                disabled={el.csv == 1}
                              />{" "}
                              {/* <input type="checkbox" name="All" value="All" /> */}
                            </td>

                            <td>{el.PO}</td>
                            <td>{el.STY}</td>
                            <td>{el.SNo}</td>

                            <td>{el.barcodeParity}</td>
                            <td>{el.BoxGroup}</td>
                            <td>{el.FACT}</td>

                            <th>{el.TOT_QTY}</th>
                            <td>{convertTimeToCountry(el.createdAt)}</td>
                            <td>{el.username}</td>
                            {sentCheckBox && (
                              <td>{convertTimeToCountry(el.shipmentTime)}</td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <h5>NO DATA FOUND</h5>
                  </td>
                </tr>
              )}
            </table>
            {isLoading && (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            // size="xs"
            // dialogClassName="modal-right"
            style={{
              position: "absolute",
              top: "90px",
              left: "72%",
              // float: "left",
              // transform: "translateX(-50%)",
              // maxWidth: "1650px",
              minWidth: "300px",
              maxWidth: "330px",
              height: "300px",
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">Scanner Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={verifyBarcode}>
                <div className="d-flex">
                  <div>
                    <label htmlFor="message-text">NVE#</label>
                    <input
                      type="text"
                      className="ms-2 "
                      style={{
                        width: "170px",
                        height: "37px",
                        borderRadius: "4px",
                      }}
                      id="recipient-name"
                      name=" NVE#"
                      placeholder="NVE#"
                      value={inputForBarcode}
                      onChange={(e) => {
                        const value = e.target.value;
                        setInputForBarcode(value.replace(/[^\d.]/g, ""));
                      }}
                    />
                  </div>
                  <div className="ms-2">
                    <button
                      className="btn btn-primary"
                      onClick={verifyBarcode}
                      hidden
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </form>

              <span>
                <span className="mt-2">
                  <span className="me-2">
                    {`Box Number : ${boxnumberofBarcode}`}
                  </span>
                  <span className="ms-4">{`Total quantity : ${totalQuantityofBarcode} `}</span>
                </span>

                {/* <button
                style={{ float: "right", width: "70px", height: "40px" }}
                type="button"
                className="btn btn-secondary mt-4"
                onClick={handleCloseModal}
              >
                Close
              </button> */}
              </span>
            </Modal.Body>
            {/* <Modal.Footer>
            {/* <button type="button" className="btn btn-primary">
              Confirm
            </button> */}
            {/* </Modal.Footer> */}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ShipmentLoading;
