/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import convertRatio from "../../../utility/convertRatio";
import nullify from "../../../utility/nullify";
import context from "../../../context/context";
import ErrosToast from "../../../components/ErrosToast";
import Combination from "./Combinations";
import AddNewEntry from "./AddNewEntry";

const calcTotal = (box) => {
  // console.log();
  return (
    parseInt(box.SZ01 || "0") +
    parseInt(box.SZ02 || "0") +
    parseInt(box.SZ03 || "0") +
    parseInt(box.SZ04 || "0") +
    parseInt(box.SZ05 || "0") +
    parseInt(box.SZ06 || "0") +
    parseInt(box.SZ07 || "0") +
    parseInt(box.SZ08 || "0") +
    parseInt(box.SZ09 || "0") +
    parseInt(box.SZ10 || "0") +
    parseInt(box.SZ11 || "0") +
    parseInt(box.SZ12 || "0")
  );
};
const InputBox = ({ box, newHeader, index, newEntryButton }) => {
  console.log("new entry", newEntryButton);
  let name, value;
  const [lot, setLot] = useState({ flag: false });
  const [lotTotal, setLotTotal] = useState("");
  const [showNewEntryRow, setShowNewEntryRow] = useState([false, false, false]); // for showing new entry row
  const ctx = useContext(context);

  // console.log("box details", ctx.boxData.length)

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/v1/lot/getOne?LOT=" +
          box.purchaseOrder.LOT,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("fetched lot");
        var lot = response.data.lot;
        setLot(lot);
        setLotTotal(
          Object.values(lot)
            .map((x) => parseInt(x))
            .reduce((c, n) => c + n)
        );
        // setSeperateLotRation(Object.values(lot).map((x) => parseInt(x)).filter((l)=>{}));
      })
      .catch((error) => console.log(error));
  }, []);
  const [userInput, setUserInput] = useState({
    SZ01: box.SZ01 || 0,
    SZ02: box.SZ02 || 0,
    SZ03: box.SZ03 || 0,
    SZ04: box.SZ04 || 0,
    SZ05: box.SZ05 || 0,
    SZ06: box.SZ06 || 0,
    SZ07: box.SZ07 || 0,
    SZ08: box.SZ08 || 0,
    SZ09: box.SZ09 || 0,
    SZ10: box.SZ10 || 0,
    SZ11: box.SZ11 || 0,
    SZ12: box.SZ12 || 0,
  });

  const [totalQty, setTotalQty] = useState(calcTotal(box));

  const handleTQty = (e) => {
    e.preventDefault();
    let totks = e.target.value;
    setTotalQty(totks);

    if (totks % lotTotal === 0) {
      const convertedValue = convertRatio(
        totks,
        Object.values(lot).map((x) => parseInt(x))
      );
      const finalValue = {
        SZ01: convertedValue[0],
        SZ02: convertedValue[1],
        SZ03: convertedValue[2],
        SZ04: convertedValue[3],
        SZ05: convertedValue[4],
        SZ06: convertedValue[5],
        SZ07: convertedValue[6],
        SZ08: convertedValue[7],
        SZ09: convertedValue[8],
        SZ10: convertedValue[9],
        SZ11: convertedValue[10],
        SZ12: convertedValue[11],
      };
      toast.remove();
      setUserInput(finalValue);

      let temp = { ...ctx.boxData[index], ...finalValue };
      let tempBoxDataArray = [...ctx.boxData];
      tempBoxDataArray[index] = temp;
      ctx.setBoxData(tempBoxDataArray);
    } else {
      // alert("here ou can enter onl multiples of" + lotTotal)
      toast.remove();
      ErrosToast(
        `  ${totks} is Incorrect Lot ratio . \n Here you can enter only multiples of :` +
          lotTotal
      );
      // setTotalQty("");
    }
  };
  const handleUserInput = (e) => {
    name = e.target.name;
    value = parseInt(e.target.value) || "";
    let finalValue = { ...userInput, [name]: value };
    setUserInput(finalValue);
    let temp = { ...ctx.boxData[index], ...finalValue };
    let tempBoxDataArray = [...ctx.boxData];
    tempBoxDataArray[index] = temp;
    ctx.setBoxData(tempBoxDataArray);
    setTotalQty(
      Object.values({ ...userInput, [name]: value }).reduce((i, n) => {
        let first = i === "" ? 0 : parseInt(i);
        let second = n === "" ? 0 : parseInt(n);
        return first + second;
      })
    );
  };

  const lotRationInCell = (x) => {
    // x == 0 ? null : x;
    if (x === 0) {
      return null;
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            padding: "5px",
            color: "red",
            fontWeight: "bolder",
          }}
        >
          {nullify(x)}
        </div>
      );
    }
  };
  // console.log('box data', ctx.boxData)

  const handleChangeNewEntry = () => {
    console.log("change in new entry");
    setShowNewEntryRow((prevState) => {
      const nextIndex = prevState.findIndex((value) => value === false);
      if (nextIndex === -1) return prevState;

      const newState = [...prevState];
      newState[nextIndex] = true;
      return newState;
    });
  };
  return (
    <>
      {!lot.flag && (
        <tbody className="">
          <tr className="table-active table-secondary border-black">
            <th style={{ paddingTop: "16px", maxWidth: "40px" }}>
              Packed_qty/Box
            </th>
            <td style={{ paddingTop: "16px" }}>{box.purchaseOrder?.PO} </td>
            <td style={{ paddingTop: "16px" }}>{box.purchaseOrder?.SEA}</td>
            <td style={{ paddingTop: "16px" }}>{box.purchaseOrder?.STY}</td>
            <td style={{ paddingTop: "16px" }}>{box.purchaseOrder?.LOT}</td>
            <td style={{ paddingTop: "16px" }}>{box.purchaseOrder?.CLR}</td>
            <td style={{ paddingTop: "16px" }}>{box.purchaseOrder?.DIM}</td>
            <td>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "2.5px",
                    color: "red",
                    fontWeight: "bolder",
                  }}
                >
                  {lotTotal === 0 ? null : lotTotal}
                </div>

                <input
                  style={{
                    marginTop: "5px",
                    textAlign: "center",
                    backgroundColor: "beige",
                    width: "50px",
                  }}
                  type={"text"}
                  value={totalQty}
                  onChange={handleTQty}
                  name="totalQty"
                  maxLength={4}
                  // disabled={box.purchaseOrder.LOT === "000"}
                  disabled
                  autoComplete="off"
                />
                {/* <div
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "red",
                    fontWeight: "bolder",
                  }}
                ></div> */}
              </div>
            </td>
            <td>
              {lotRationInCell(lot.SR01)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                value={nullify(userInput.SZ01)}
                onChange={handleUserInput}
                maxLength={4}
                name="SZ01"
                // disabled={
                //   newHeader[0] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[0] === ""
                    ? "hidden"
                    : nullify(userInput.SZ01) === "" &&
                      box.purchaseOrder.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ01
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR02)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ02)}
                onChange={handleUserInput}
                name="SZ02"
                // disabled={
                //   newHeader[1] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[1] === ""
                    ? "hidden"
                    : nullify(userInput.SZ02) === "" &&
                      box.purchaseOrder.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ02
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR03)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ03)}
                onChange={handleUserInput}
                name="SZ03"
                // disabled={
                //   newHeader[2] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[2] === ""
                    ? "hidden"
                    : nullify(userInput.SZ03) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ03
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR04)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ04)}
                onChange={handleUserInput}
                name="SZ04"
                // disabled={
                //   newHeader[3] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[3] === ""
                    ? "hidden"
                    : nullify(userInput.SZ04) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ04
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR05)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ05)}
                onChange={handleUserInput}
                name="SZ05"
                // disabled={
                //   newHeader[4] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[4] === ""
                    ? "hidden"
                    : nullify(userInput.SZ05) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ05
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR06)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ06)}
                onChange={handleUserInput}
                name="SZ06"
                // disabled={
                //   newHeader[5] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[5] === ""
                    ? "hidden"
                    : nullify(userInput.SZ06) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ06
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR07)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ07)}
                onChange={handleUserInput}
                name="SZ07"
                // disabled={
                //   newHeader[6] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[6] === ""
                    ? "hidden"
                    : nullify(userInput.SZ07) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ07
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR08)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ08)}
                onChange={handleUserInput}
                name="SZ08"
                // disabled={
                //   newHeader[7] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[7] === ""
                    ? "hidden"
                    : nullify(userInput.SZ08) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ08
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR09)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ09)}
                onChange={handleUserInput}
                name="SZ09"
                // disabled={
                //   newHeader[8] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[8] === ""
                    ? "hidden"
                    : nullify(userInput.SZ09) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ09
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR10)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ10)}
                onChange={handleUserInput}
                name="SZ10"
                // disabled={
                //   newHeader[9] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[9] === ""
                    ? "hidden"
                    : nullify(userInput.SZ10) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                    ? "hidden"
                    : userInput.SZ10
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR11)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ11)}
                onChange={handleUserInput}
                name="SZ11"
                // disabled={
                //   newHeader[10] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[10] === ""
                    ? "hidden"
                    : nullify(userInput.SZ11) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                }
                autoComplete="off"
              />
            </td>
            <td>
              {lotRationInCell(lot.SR12)}
              <input
                type={"text"}
                style={{ textAlign: "center" }}
                maxLength={4}
                value={nullify(userInput.SZ12)}
                onChange={handleUserInput}
                name="SZ12"
                // disabled={
                //   newHeader[11] === "" || box.purchaseOrder.LOT !== "000"
                // }
                disabled
                className={
                  newHeader[11] === ""
                    ? "hidden"
                    : nullify(userInput.SZ12) === "" &&
                      box?.purchaseOrder?.LOT !== "000"
                }
                autoComplete="off"
              />
            </td>
          </tr>

          <Combination
            userInput={userInput}
            lotTotal={lotTotal}
            totalQty={totalQty}
            handleTQty={handleTQty}
            box={box}
            lot={lot}
            lotRationInCell={lotRationInCell}
            handleUserInput={handleUserInput}
            newHeader={newHeader}
            index={index}
          />
          {showNewEntryRow[0] && (
            <AddNewEntry
              userInput={userInput}
              lotTotal={lotTotal}
              totalQty={totalQty}
              handleTQty={handleTQty}
              box={box}
              lot={lot}
              lotRationInCell={lotRationInCell}
              handleUserInput={handleUserInput}
              newHeader={newHeader}
              index={ctx.boxData.length}
            />
          )}
          {showNewEntryRow[1] && (
            <AddNewEntry
              userInput={userInput}
              lotTotal={lotTotal}
              totalQty={totalQty}
              handleTQty={handleTQty}
              box={box}
              lot={lot}
              lotRationInCell={lotRationInCell}
              handleUserInput={handleUserInput}
              newHeader={newHeader}
              index={ctx.boxData.length+1}
            />
          )}
          {showNewEntryRow[2] && (
            <AddNewEntry
              userInput={userInput}
              lotTotal={lotTotal}
              totalQty={totalQty}
              handleTQty={handleTQty}
              box={box}
              lot={lot}
              lotRationInCell={lotRationInCell}
              handleUserInput={handleUserInput}
              newHeader={newHeader}
              // index={
              //   ctx.newBoxDataForSpecific &&
              //   ctx.newBoxDataForSpecific.length > 0
              //     ? ctx.newBoxDataForSpecific.length
              //     : ctx.boxData.length
              // }
              index={ctx.boxData.length+2}
            />
          )}

          {newEntryButton === true && (
            <div
              className="p-2"
              style={{ border: "none", width: "160px", height: "25px" }}
            >
              <button
                className="btn btn-success d-flex align-items-center "
                style={{ width: "110px", backgroundColor: "#00CED1" }}
                onClick={handleChangeNewEntry}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Add Entry
                </span>
                <div className="ms-2" style={{ width: "40px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                  </svg>
                </div>
              </button>
            </div>
          )}
        </tbody>
      )}
      <br />
    </>
  );
};
export default InputBox;
