import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { div, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Header from "../../../components/header";
import Headroom from "react-headroom";
import sortByColumn from "../../../utility/sorting";
import Loader from "../../../components/Loader";
import ReactPaginate from "react-paginate";
import context from "../../../context/context";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrosToast from "../../../components/ErrosToast";
//import { object } from "underscore";

const Boxtype = () => {
  const [boxApiData, setBoxApiData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const blankState = {
    type: "",
    // NetWt: "",
    // GrossWt: "",
    Height: "",
    Width: "",
    Length: "",
    Description: "",
  };
  const [boxTypeInputField, setBoxTypeInputField] = useState(blankState);

  //for sorting data(asending or desending data)
  const [emojiOperator, setEmojiOperator] = useState({ boxType: "⬆" });
  const [dataInIncrement, setDataInIncrement] = useState([]);
  const [dataInDecrement, setDataInDecrement] = useState([]);
  const [showIncrement, setShowIncrement] = useState(true);

  const [dataLoading, setDataLoading] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const ctx = useContext(context);

  const handlBoxTypeInput = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // Validation for Field 1: Allow only numbers alphabets and uppercase letters
    if (name === "type") {
      updatedValue = value.replace(/[^A-Za-z0-9]/g, "");
    }

    // Validation for Field 2 and Field 3: Allow only numbers with up to 3 decimal places
    // if (name === "NetWt" || name === "GrossWt") {
    //   updatedValue = value.replace(/[^0-9.]/g, "");
    //   const parts = updatedValue.split(".");
    //   if (parts.length > 1) {
    //     updatedValue = `${parts[0]}.${parts[1].slice(0, 3)}`;
    //   }
    // }

    // Validation for Field 4 and Field 5: Allow only integers
    if (name === "Height" || name === "Width" || name === "Length") {
      updatedValue = value.replace(/[^0-9]/g, "");
    }

    setBoxTypeInputField({
      ...boxTypeInputField,
      [name]: updatedValue.toUpperCase(),
    });
  };

  // const fetchData = (pageNo, limit) => {
  //   // limit = limit+30;
  //   // if (dataLoading) return;
  //   setDataLoading(true);

  //   axios
  //     .get(
  //       process.env.REACT_APP_API_LINK +
  //         // `/api/v1/admin/getAllBoxMasters?pageNo=${0}&docNo=${30}`
  //         `/api/v1/admin/getAllBoxMasters?pageNo=${pageNo}&docNo=${limit}`
  //       // ?pageNo=${e.selected}&docNo=${30}
  //     )
  //     .then((res) => {
  //       //.log("boslist", res.data.boxMasters);
  //       console.log("boxtype> ", res);

  //       setDataLoading(false);
  //       setBoxApiData([...res.data.boxMasters], [boxTypeInputField]);
  //       setPage(pageNo);
  //       // setTimeout(() => {
  //       //   const tableContainer = document.querySelector("table");
  //       //   if (tableContainer) {
  //       //     tableContainer.scrollIntoView({
  //       //       behavior: "instant",
  //       //       block: "start",
  //       //     });
  //       //   }
  //       // }, 100);
  //       // ctx.setPageCountBoxType(res.data.pageCount);
  //       // ctx.setCurrentPageBoxType(0);
  //       if (res.data.boxMasters.length < limit) {
  //         setHasMore(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setDataLoading(false);
  //       console.log("errr", err);
  //     });
  // };

  const fetchData = async (pageNo, limit) => {
    setDataLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_LINK +
          `/api/v1/admin/getAllBoxMasters?pageNo=${pageNo}&docNo=${limit}`
      );
      // console.log("boxtype> ", res);
      setBoxApiData([...res.data.boxMasters], [boxTypeInputField]);
      setPage(pageNo);
      if (res.data.boxMasters.length < limit) {
        setHasMore(false);
      }
      setInitialFetchDone(true); // Set initialFetchDone to true after initial fetch
    } catch (err) {
      // console.log("errr", err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    // console.log("calling me??");
    if (!initialFetchDone) {
      // console.log("is it calling ");
      setDataLoading(true);
      fetchData(0, 30);
      // setTimeout(()=> {
      //   setInitialFetchDone(true);
      // },500)
      // Set initialFetchDone to true after initial fetch
      return;
    }
    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");

      if (lastRow) {
        // console.log("boxapi length", boxApiData.length);
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;
        // console.log('checking last data or not', containerOffset > lastRowOffset - bottomOffset)
        // if(containerOffset > lastRowOffset - bottomOffset)
        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !dataLoading
        ) {
          fetchData(0, 30 * ((boxApiData.length + 30) / 30));
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [page, dataLoading]);

  // console.log("boxtyp length from outside", boxApiData.length);

  const handlePageClick = (e) => {
    // for pagination handle function
    setDataLoading(true);
    axios
      .get(
        process.env.REACT_APP_API_LINK +
          `/api/v1/admin/getAllBoxMasters?pageNo=${e.selected}&docNo=${30}`
      )
      .then((res) => {
        //ctx.setPageCountTransferData(Math.ceil((res.data.entries.length/30)+1));
        setBoxApiData([...res.data.boxMasters], [boxTypeInputField]);
        setDataLoading(false);
        ctx.setCurrentPageBoxType(e.selected);
      })
      .catch((error) => {
        // Handle error if the axios request fails
        console.error("Error fetching data:", error);
        setDataLoading(false);
      });
  };

  useEffect(() => {
    setDataInDecrement(boxApiData);
    setDataInIncrement(boxApiData);
  }, [boxApiData]);

  //console.log('boxType input field', boxTypeInputField)
  const createBoxType = (e) => {
    e.preventDefault();
    const type = boxTypeInputField.type;
    const boxTypeData = boxApiData.find((data) => data.type === type);
    //console.log('box typeee', boxTypeData)
    if (
      !boxTypeInputField.type ||
      // !boxTypeInputField.GrossWt ||
      !boxTypeInputField.Height ||
      !boxTypeInputField.Length ||
      !boxTypeInputField.Width
      // !boxTypeInputField.NetWt
    ) {
      alert("Please Check above field and fill Input field");
    } else if (boxTypeData) {
      toast.remove();

      ErrosToast(`${type} is already there. Please create with new name`);
      return;
    } else {
      setIsLoadingCreate(true);
      axios
        .post(
          process.env.REACT_APP_API_LINK + "/api/v1/admin/createBoxMaster",
          { data: boxTypeInputField },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          //console.log("data", res);
          setBoxApiData((prevData) => [...prevData, res.data.data]);
          setBoxTypeInputField(blankState);
          toast.success(
            ` Box Type ${boxTypeInputField.type.toUpperCase()} Created`
          );
          setIsLoadingCreate(false);
          //window.location.reload()
        })
        .catch((err) => {
          setIsLoadingCreate(false);
          // console.log(err);
        });
    }
  };

  function updateBoxType(id) {
    setIsUpdate(true);
    const boxTypeData = boxApiData.find((data) => data.id === id);
    setBoxTypeInputField({
      id: boxTypeData.id,
      type: boxTypeData.type,
      // NetWt: boxTypeData.NetWt,
      // GrossWt: boxTypeData.GrossWt,
      Length: boxTypeData.Length,
      Width: boxTypeData.Width,
      Height: boxTypeData.Height,
      Description: boxTypeData.Description || "",
    });
    //setIsUpdate(false);
  }

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    if (
      !boxTypeInputField.type ||
      // !boxTypeInputField.GrossWt ||
      !boxTypeInputField.Height ||
      !boxTypeInputField.Length ||
      !boxTypeInputField.Width
      // !boxTypeInputField.NetWt
    ) {
      alert("Please Check above field and fill Input field");
    } else {
      const boxTypeData = boxApiData.find(
        (data) => data.id === boxTypeInputField.id
      );
      //console.log("boxTypeData before update:", boxTypeData);
      setIsLoadingUpdate(true);
      axios
        .post(
          process.env.REACT_APP_API_LINK + "/api/v1/admin/updateBoxMaster",
          { data: boxTypeInputField, id: boxTypeData.id }
        )
        .then((res) => {
          //console.log('res', res)
          setBoxApiData((prevData) => {
            //console.log('prev data', prevData)
            return prevData.map((data) =>
              data.type === boxTypeData.type
                ? { ...data, ...boxTypeInputField }
                : data
            );
          });

          //console.log("boxApiData after update:", boxApiData);

          setBoxTypeInputField(blankState);
          setIsUpdate(false);
          toast.success(
            ` Box Type ${boxTypeInputField.type.toUpperCase()} updated`
          );
          setIsLoadingUpdate(false);
          // setBoxApiData([...Boxtype, ...boxTypeInputField]);

          // let temp = Boxtype;
          // console.log("temp", temp);
        })
        .catch((err) => {
          console.error("Error updating box type:", err.response);
          setIsLoadingUpdate(false);
          if (err.response) {
            console.error("Status:", err.response.status);
            console.error("Data:", err.response.data);
          }
        });
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsUpdate(false);
    setBoxTypeInputField(blankState);
  };

  const deleteBoxType = async (type, i) => {
    setBoxApiData((prevData) =>
      prevData.map((boxType) =>
        boxType.type === type ? { ...boxType, isLoadingDelete: true } : boxType
      )
    );
    //setIsDeleteLoading(true);
    if (
      window.confirm(`Do you Want to delete the data of Box type :  ${type} ?`)
    ) {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_LINK +
              "/api/v1/admin/deleteBoxMaster?type=" +
              type
          )
          .then((res) => {
            toast.success(`Box Type : ${type}  data Deleted`);
            setBoxApiData((prevData) =>
              prevData.filter((el) => type !== el.type)
            );
          });
      } catch (error) {
        // console.log(error);
      } finally {
        setBoxApiData((prevData) =>
          prevData.map((boxType) => ({ ...boxType, isLoadingDelete: false }))
        );
      }
    } else {
      //setIsDeleteLoading(false);
      setBoxApiData((prevData) =>
        prevData.map((boxType) => ({ ...boxType, isLoadingDelete: false }))
      );
      toast.remove();

      ErrosToast(`"No Record Deleted"`);
      return;
    }
  };

  const emojiOperatorChange = (e) => {
    const name = e.target.dataset.name;

    if (name === "boxType") {
      setEmojiOperator({
        ...emojiOperator,
        boxType: emojiOperator.boxType === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        boxApiData,
        "type",
        emojiOperator.boxType
      );
      emojiOperator.boxType === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.boxType === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    }
  };

  const currentData = showIncrement ? dataInDecrement : dataInIncrement;

  return (
    <>
      <div className="fixed-top">
        <Header />
        <div className=" px-4 py-2 rounded" style={{ background: "white" }}>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="input7">
                  <Form.Label>BoxType</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="type"
                    autoComplete="off"
                    value={boxTypeInputField.type.toUpperCase()}
                    placeholder="Box Type"
                    maxLength={30}
                    required
                    onChange={handlBoxTypeInput}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="input7">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text-area"
                    name="Description"
                    autoComplete="off"
                    value={boxTypeInputField.Description}
                    placeholder="Description"
                    maxLength={50}
                    style={{ border: "2px solid #000" }}
                    onChange={handlBoxTypeInput}
                  />
                </Form.Group>
              </Col>

              {/* <Col>
                <Form.Group controlId="input2">
                  <Form.Label>NetWt(kg/gm)</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="NetWt"
                    autoComplete="off"
                    value={boxTypeInputField.NetWt}
                    placeholder="NetWt"
                    maxLength={7}
                    style={{ border: "2px solid #000" }}
                    onChange={handlBoxTypeInput}
                  />
                </Form.Group>
              </Col> */}
              {/* <Col>
                <Form.Group controlId="input3">
                  <Form.Label>GrossWt(kg/gm)</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="GrossWt"
                    autoComplete="off"
                    value={boxTypeInputField.GrossWt}
                    placeholder="GrossWt"
                    maxLength={7}
                    style={{ border: "2px solid #000" }}
                    onChange={handlBoxTypeInput}
                  />
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group controlId="input4">
                  <Form.Label>Length(cm)</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="Length"
                    autoComplete="off"
                    value={boxTypeInputField.Length}
                    placeholder="Length"
                    maxLength={7}
                    style={{ border: "2px solid #000" }}
                    onChange={handlBoxTypeInput}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="input5">
                  <Form.Label>Width(cm)</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="Width"
                    autoComplete="off"
                    value={boxTypeInputField.Width}
                    placeholder="Width"
                    maxLength={7}
                    style={{ border: "2px solid #000" }}
                    onChange={handlBoxTypeInput}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="input5">
                  <Form.Label>Height(cm)</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="Height"
                    autoComplete="off"
                    value={boxTypeInputField.Height}
                    placeholder="Height"
                    maxLength={7}
                    style={{ border: "2px solid #000" }}
                    onChange={handlBoxTypeInput}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Form.Label>
                {isUpdate ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      variant="btn btn-info"
                      type="submit"
                      onClick={handleUpdateSubmit}
                      className="me-2"
                    >
                      {isLoadingUpdate ? <Spinner size="sm" /> : "Update"}
                    </Button>
                    <Button
                      variant="btn btn-danger"
                      type="submit"
                      className="ms-2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={createBoxType}
                    className="mt-4"
                  >
                    {isLoadingCreate ? <Spinner size="sm" /> : "Create"}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      {dataLoading && !initialFetchDone ? (
        <div className="" style={{ marginTop: "220px" }}>
          <Loader />
        </div>
      ) : (
        <div
          className="px-4 table-responsive tableFixHead table-container"
          style={{ marginTop: "185px", marginBottom: "40px" }}
        >
          {/* <InfiniteScroll
            dataLength={currentData.length}
            next={() => fetchData(0, 30 * ((currentData.length + 30)/30))}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<p>No more items</p>}
            scrollableTarget="table-container"
          > */}
          <table
            className="table table-bordered table border-dark table-header-for-all"
            style={{ minWidth: "1280px", mamaxWidth: "1280px" }}
          >
            <thead className="fixed-header">
              <tr className="table-warning">
                <th>S.No.</th>
                <th
                  data-name="boxType"
                  onClick={emojiOperatorChange}
                >{`BoxType${emojiOperator.boxType}`}</th>
                <th>Description</th>
                {/* <th>NetWt</th> */}
                {/* <th>GrossWt</th> */}
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentData &&
                currentData.map((el, i) => {
                  // console.log("el", el);
                  return (
                    <tr
                      className="table-default"
                      style={{
                        backgroundColor:
                          el.type === boxTypeInputField.type ? "grey" : "",
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{el.type}</td>
                      <td>{el.Description}</td>
                      {/* <td>{el.NetWt}</td> */}
                      {/* <td>{el.GrossWt}</td> */}
                      <td>{el.Length}</td>
                      <td>{el.Width}</td>
                      <td>{el.Height}</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            onClick={() => updateBoxType(el.id)}
                            className="btn btn-info me-2"
                          >
                            Modify
                          </button>

                          <button
                            type="button"
                            onClick={() => deleteBoxType(el.type)}
                            className="btn btn-danger ms-1"
                          >
                            {el.isLoadingDelete ? (
                              <Spinner size="sm" />
                            ) : (
                              "Delete"
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {dataLoading && initialFetchDone && (
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {/* {!dataLoading && !hasMore && <div>No more data</div>} */}

          {/* </InfiniteScroll> */}
          {/* {!!ctx.pageCountBoxType && (
            <ReactPaginate //!!ctx.pageCountPoSummary &&
              disableInitialCallback={true}
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={ctx.pageCountBoxType}
              forcePage={ctx.currentPageBoxType}
              // initialPage={ctx.currentPage}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default Boxtype;
