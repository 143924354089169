/* eslint-disable no-undef */
import axios from "axios";
import React, { useContext, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-hot-toast";
import Header from "../../../components/header";
import { useEffect } from "react";
import Loader from "../../../components/Loader";
//import moment from "moment-timezone";
import convertTimeToCountry from "../../../utility/convertTimeToCountry";
// import Headroom from "react-headroom";
import ReactPaginate from "react-paginate";
import context from "../../../context/context";
import { useCallback } from "react";
import { debounce } from "underscore";
import ErrosToast from "../../../components/ErrosToast";

const RangeCode = () => {
  // const [rangeApi, setRangeApi] = useState([]);
  const [lastBarcodeRange, setLastBarCodeRange] = useState(0);
  const [loading, setLoading] = useState(true);
  const nveBlankstate = {
    id: "",
    from: "",
    totalNve: "",
    to: "",
  };
  const [nveRangeBox, setnveRangeBox] = useState(nveBlankstate);

  const [searchInput, setSearchInput] = useState("");
  const [filteredNve, setFilteredNve] = useState([]);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false); // to loading for create

  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const ctx = useContext(context);
  // Use useMemo to memoize the nveRangeBox value
  const memoizedApiResult = useCallback(async (pageNo, limit) => {
    setLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_LINK +
          `/api/v1/admin/getAllBarcodeRanges?pageNo=${pageNo}&docNo=${limit}`
      );
      // console.log("barcode range data", res.data.barcodeRanges);
      setLastBarCodeRange(res.data.last.to);
      ctx.setRangeApi([...res.data.barcodeRanges]);
      setFilteredNve([...res.data.barcodeRanges]);
      setnveRangeBox({
        ...nveRangeBox,
        from: BigInt(res.data.last.to) + BigInt(1),
      });
      setInitialFetchDone(true);
      // const realCount =
      //   res.data.pageCount >= 1
      //     ? res.data.pageCount + 1
      //     : res.data.pageCount;
      // ctx.setPageCountRangeCode(Math.ceil(realCount));
      // ctx.setCurrentPageRangeCode(0);
      // setLoading(false);
      setPage(pageNo);

      if (res.data.barcodeRanges.length < limit) {
        setHasMore(false);
      }
    } catch (err) {
      // setLoading(false);
      // console.log("error", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // console.log("use effect called.");
    // memoizedApiResult(0,100)
    // Code to execute after API call completion
    if (!initialFetchDone) {
      memoizedApiResult(0, 30);
      // Set initialFetchDone to true after initial fetch
      return;
    }

    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");

      if (lastRow) {
        // console.log("boxapi length", ctx.rangeApi.length);
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;

        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !loading
        ) {
          memoizedApiResult(0, 30 * ((ctx.rangeApi.length + 30) / 30));
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
    // });
  }, [page, loading]);

  // useEffect(() => {
  //   memoizedApiResult.then(() => {
  //     // Code to execute after API call completion
  //   });
  // }, [nveRangeBox]);

  // console.log("nveRangeBox", nveRangeBox);

  const handlePageClick = (e) => {
    // for pagination handle function
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_API_LINK +
          `/api/v1/admin/getAllBarcodeRanges?nveId=${searchInput}&pageNo=${
            e.selected
          }&docNo=${30}`
      )
      .then((res) => {
        //ctx.setPageCountTransferData(Math.ceil((res.data.entries.length/30)+1));
        // console.log("barcode range data", res);
        setFilteredNve(res.data.barcodeRanges);
        setLastBarCodeRange(res.data.last.to);
        ctx.setRangeApi([...res.data.barcodeRanges]);
        setnveRangeBox({
          ...nveRangeBox,
          from: BigInt(res.data.last.to) + BigInt(1),
        });
        ctx.setCurrentPageRangeCode(e.selected);
        setLoading(false);
      })
      .catch((error) => {
        // Handle error if the axios request fails
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleNveBoxInput = (e) => {
    // e.preventDefault();
    let name = e.target.name;
    let value = e.target.value.toUpperCase();

    if (name === "from") {
      setnveRangeBox((prev) => {
        return {
          ...prev,
          [name]: value,
          to: BigInt(prev.totalNve) + BigInt(value) - BigInt(1),
        };
      });
    } else if (name === "totalNve") {
      setnveRangeBox((prev) => {
        return {
          ...prev,
          [name]: value,
          to: BigInt(prev.from) + BigInt(value) - BigInt(1),
        };
      });
    } else {
      setnveRangeBox((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const CreateNveRange = async (e) => {
    e.preventDefault();
    if (
      !nveRangeBox.id ||
      !nveRangeBox.from ||
      !nveRangeBox.to ||
      !nveRangeBox.totalNve
    ) {
      alert("Please Check above field and fill Input field");
    } else if (nveRangeBox.from <= lastBarcodeRange) {
      alert("given range is already Assigned to Previous ids");
    } else {
      // return alert(JSON.stringify(nveRangeBox));
      setIsLoadingCreate(true);
      try {
        const res = await axios.post(
          "/admin/createBarcodeRange",

          {
            data: {
              ...nveRangeBox,
              to: nveRangeBox.to.toString(),
              from: nveRangeBox.from.toString(),
            },
          },

          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const newItem = {
          ...res.data.data.dataValues,
          total: res.data.data.total,
          used: res.data.data.used,
          usernames: res.data.data.usernames,
        };
        setnveRangeBox((prev) => {
          return { ...nveBlankstate, from: BigInt(prev.to) + BigInt(1) };
        });
        ctx.setRangeApi((prev) => [newItem, ...prev]);

        // console.log("respose data after creating", res.data);
        // console.log([...filteredNve, newItem]);
        setFilteredNve((prev) => [newItem, ...prev]);
        toast.success(
          ` nveRange code ${nveRangeBox.id.toLocaleUpperCase()} Created`
        );
        setIsLoadingCreate(false);
      } catch (err) {
        if (err.response.data.type === "exists")
          ErrosToast("Given ID alrady created");
        else ErrosToast("Something went wrong, Please check");
        setIsLoadingCreate(false);
      } finally {
        setIsLoadingCreate(false);
      }
    }
  };

  const updateNveRange = (e) => {
    e.preventDefault();
    alert("In process");
  };

  const deleteNveRange = async (id) => {
    ctx.setRangeApi((prevData) =>
      prevData.map((rangeData) =>
        rangeData.id === id
          ? { ...rangeData, isLoadingDelete: true }
          : rangeData
      )
    );
    setFilteredNve((prevData) =>
      prevData.map((rangeData) =>
        rangeData.id === id
          ? { ...rangeData, isLoadingDelete: true }
          : rangeData
      )
    );
    if (
      window.confirm(`Do you Want to delete the id of NVE-RANGE :  ${id} ?`)
    ) {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_LINK +
              "/api/v1/admin/deleteBarcodeRange?id=" +
              id
          )
          .then((res) => {
            // console.log("is something here in res of delete api", res);
            toast.success(`Range Id: ${id} data Deleted`);
            ctx.setRangeApi((prevData) =>
              prevData.filter((el) => id !== el.id)
            );
            setFilteredNve((prevData) => prevData.filter((el) => id !== el.id));
          });
      } catch (err) {
        // console.log(err);
      } finally {
        ctx.setRangeApi((prevData) =>
          prevData.map((rangeData) => ({
            ...rangeData,
            isLoadingDelete: false,
          }))
        );
        setFilteredNve((prevData) =>
          prevData.map((rangeData) => ({
            ...rangeData,
            isLoadingDelete: false,
          }))
        );
        // setnveRangeBox((prev) => {
        //   return { ...nveBlankstate, from: BigInt(prev.to) + BigInt(1) };
        // });
        await memoizedApiResult(0, 30);
      }
    } else {
      ctx.setRangeApi((prevData) =>
        prevData.map((rangeData) => ({ ...rangeData, isLoadingDelete: false }))
      );
      setFilteredNve((prevData) =>
        prevData.map((rangeData) => ({ ...rangeData, isLoadingDelete: false }))
      );
      ErrosToast("No Record Deleted");
    }
  };

  const handleFilteredNveId = (e, value) => {
    // e.preventDefault();
    // const filteredData = rangeApi.filter((item) => {
    //   return (
    //     item.id.toString().toUpperCase().indexOf(searchInput.toUpperCase()) > -1
    //   );
    // });
    e.preventDefault();
    // console.log('search input ', value)
    setLoading(true);
    const encodedNveId = encodeURIComponent(value);
    return (
      axios
        .get(
          process.env.REACT_APP_API_LINK +
            `/api/v1/admin/getAllBarcodeRanges?nveId=${encodedNveId}&pageNo=${0}&docNo=${30}`
        )
        .then((res) => {
          // console.log("filtered barcode range data", res);
          // console.log();
          setFilteredNve(res.data.barcodeRanges);
          setLastBarCodeRange(res.data.last.to);
          ctx.setRangeApi([...res.data.barcodeRanges]);
          setnveRangeBox({
            ...nveRangeBox,
            from: BigInt(res.data.last.to) + BigInt(1),
          });
          if (res.data.barcodeRanges.length < limit) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          // if(res.data.pageCount >= 1){
          //   const realCount = res.data.pageCount +1
          // }
          // else {
          //   const realCount = res.data.page
          // }
          const realCount =
            res.data.pageCount >= 1
              ? res.data.pageCount + 1
              : res.data.pageCount;
          ctx.setPageCountRangeCode(Math.ceil(realCount));
          ctx.setCurrentPageRangeCode(0);
          setLoading(false);
        })
        // You can also update nveRangeBox here if needed
        // setnveRangeBox((prev) => {
        //   return { ...prev, from: res.data.last.to };
        // });
        //  })
        .catch((err) => {
          setLoading(false);
          // console.log("error", err);
        })
    );

    // setFilteredNve(filteredData);
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce((e, value) => handleFilteredNveId(e, value), 1000, false),
    []
  );
  // console.log("range api data", ctx.rangeApi);
  // console.log('filtered range api data', filteredNve)

  return (
    <>
      <div className="fixed-top">
        <Header />
        <div style={{ background: "#ffff", padding: "1% 1%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // border: "2px solid green",
              width: "100%",
              background: "#ffff",
              padding: "0% 0% 1% 1%",
            }}
          >
            <div
              style={
                {
                  // border: "2px solid red"
                }
              }
            >
              <Form>
                <Row style={{ textTransform: "capitalize" }}>
                  <Col>
                    <Form.Group controlId="input7">
                      <Form.Label className="fs-6">NVE Range Code</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="id"
                        autoComplete="off"
                        value={nveRangeBox.id}
                        placeholder="Range Code"
                        maxLength={6}
                        style={{ border: "2px solid #000" }}
                        onChange={handleNveBoxInput}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="input2">
                      <Form.Label className="fs-6"> barcode From</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="from"
                        autoComplete="off"
                        value={nveRangeBox.from.toString()}
                        placeholder="From"
                        maxLength={17}
                        style={{ border: "2px solid #000" }}
                        onChange={handleNveBoxInput}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="input8">
                      <Form.Label className="fs-6">Total NVE</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="totalNve"
                        autoComplete="off"
                        value={nveRangeBox.totalNve}
                        placeholder=" Total NVE"
                        maxLength={6}
                        style={{ border: "2px solid #000" }}
                        onChange={handleNveBoxInput}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="input3">
                      <Form.Label className="fs-6"> barcode To</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="to"
                        autoComplete="off"
                        value={nveRangeBox.to.toString()}
                        placeholder="To"
                        maxLength={17}
                        style={{ border: "2px solid #000" }}
                        onChange={handleNveBoxInput}
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Button
                      variant="primary"
                      size="md"
                      type="submit"
                      onClick={CreateNveRange}
                      className="mt-4 p-2 text-center"
                      disabled={isLoadingCreate}
                    >
                      {isLoadingCreate ? <Spinner size="sm" /> : "Create"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div
              style={{
                //  border: "2px solid blue",
                width: "40%",
              }}
            >
              <Form
                className="mt-3 "
                onSubmit={(e) => handleFilteredNveId(e, searchInput)}
              >
                <Row>
                  <Col lg={3} md={2}>
                    <FormControl
                      className="mt-2"
                      size="sm"
                      type="text"
                      name="nveId"
                      autoComplete="off"
                      value={searchInput}
                      placeholder="Search NVE ID"
                      maxLength={6}
                      style={{ border: "2px solid #000", width: "200px" }}
                      onChange={(e) => {
                        setSearchInput(e.target.value.toUpperCase());
                        OnChangeHandleFilter(e, e.target.value.toUpperCase());
                      }}
                    />
                  </Col>
                  <Col
                    style={{
                      paddingLeft: "113px",
                      height: "5vh",
                      // paddingTop: "9%",
                      width: "40%",
                      // border: "2px solid red",
                    }}
                  >
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={(e) => handleFilteredNveId(e, searchInput)}
                      className="text-center py-2"
                      size="sm"
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {loading && !initialFetchDone ? (
        <div style={{ marginTop: "205px" }}>
          <Loader />
        </div>
      ) : (
        <div
          className="mx-4 tableFixHead table-container"
          style={{ marginTop: "205px", marginBottom: "40px" }}
        >
          <table className="table table-bordered border-dark table-header-for-all">
            <thead>
              <tr className="table-warning">
                <th>S.No</th>
                <th>NVE Id</th>
                <th> Barcode From</th>
                <th> Barcode To</th>
                <th>Total</th>
                <th>Used</th>
                <th>User</th>
                <th>Created Date</th>
                <th>Last Used Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredNve.length > 0
                ? filteredNve.map((el, i) => {
                    return (
                      <tr className="table-default" key={el.id}>
                        <td>{i + 1}</td>
                        <td>{el.id}</td>
                        <td>{el.from}</td>
                        <td>{el.to}</td>
                        <td>{el.total}</td>
                        <td>{el.used}</td>

                        <td>
                          {el.usernames.length === 0
                            ? "Not Assigned"
                            : el.usernames.length > 1
                            ? `${el.usernames[0]} + ${el.usernames.length - 1}`
                            : el.usernames[0]}
                        </td>
                        {/* `${el.usernames[0]} + ${el.usernames.length -1}` */}
                        {/* <td>{el.usernames}</td> */}
                        <td>{convertTimeToCountry(el.createdAt)}</td>
                        <td>{convertTimeToCountry(el.updatedAt)}</td>
                        <td
                          className="d-flex justify-content-center align-items-center"
                          style={{ border: "none" }}
                        >
                          <button
                            type="submit"
                            onClick={updateNveRange}
                            className="btn btn-info siz-sm mx-2"
                          >
                            Update
                          </button>

                          <button
                            variant="primary"
                            type="submit"
                            onClick={() => {
                              deleteNveRange(el.id);
                            }}
                            className="btn btn-danger siz-sm"
                            disabled={el.used > 0}
                          >
                            {el.isLoadingDelete ? (
                              <Spinner size="sm" />
                            ) : (
                              "Delete"
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                : ctx.rangeApi.map((el, i) => {
                    //console.log(el);
                    return (
                      <tr className="table-default" key={el.id}>
                        <td>{i + 1}</td>
                        <td>{el.id}</td>
                        <td>{el.from}</td>
                        <td>{el.to}</td>
                        <td>{el.total}</td>
                        <td>{el.used}</td>
                        <td>
                          {el.usernames.length === 0
                            ? "Not Assigned"
                            : el.usernames.length > 1
                            ? `${el.usernames[0]} + ${el.usernames.length - 1}`
                            : el.usernames[0]}
                        </td>
                        {/* <td>{el.usernames}</td> */}
                        <td>{convertTimeToCountry(el.createdAt)}</td>
                        <td>{convertTimeToCountry(el.updatedAt)}</td>
                        <td
                          className="d-flex justify-content-center align-items-center"
                          style={{ border: "none" }}
                        >
                          <button
                            type="submit"
                            onClick={updateNveRange}
                            className="btn btn-info siz-sm mx-2"
                          >
                            Update
                          </button>

                          <button
                            variant="primary"
                            type="submit"
                            onClick={() => {
                              deleteNveRange(el.id);
                            }}
                            className="btn btn-danger siz-sm"
                            disabled={el.used > 0}
                          >
                            {el.isLoadingDelete ? (
                              <Spinner size="sm" />
                            ) : (
                              "Delete"
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {loading && (
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {/* {!loading && !hasMore && <div>No more data</div>} */}
          {/* {!!ctx.pageCountRangeCode && (
            <ReactPaginate //!!ctx.pageCountPoSummary &&
              disableInitialCallback={true}
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={ctx.pageCountRangeCode}
              forcePage={ctx.currentPageRangeCode}
              // initialPage={ctx.currentPage}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default RangeCode;
